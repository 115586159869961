import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home, Contact, Agence, Actualites, Filtre } from './containers';
import {Archive} from './containers';
import { CustomNavbar, Footer, Article, ScrollTopButton, MainArticle } from './components';
import './App.css';
import $ from "jquery";

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
  width: 3px; /* Assez large pour donner une place à une forme circulaire */
}

/* Piste de la scrollbar (arrière-plan) */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 10px;
}

/* Le pouce (partie glissante) */
::-webkit-scrollbar-thumb {
  background: #888;
  
}

/* Au survol de la souris */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`;

class ErrorBoundary extends React.Component { constructor(props) { super(props); this.state = { hasError: false }; } static getDerivedStateFromError(error) { return { hasError: true }; } componentDidCatch(error, errorInfo) { console.error("ErrorBoundary caught an error", error, errorInfo); } render() { if (this.state.hasError) { return <h1>Something went wrong.</h1>; } return this.props.children; } }
const App = () => {
 
  // Charger jQuery
  const jQueryScript = document.createElement("script"); // Ajoutez const ici
  jQueryScript.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.4/jquery.min.js";
  jQueryScript.integrity =
    "sha512-oEPeR8thQDkZjTbzFOzvJhV8Ke5gkQOT4vjP8a5uJ9k4E7oA7s3P07ZQK3kpVNBTRWlT+N5L8KQo29MUtxQuOw==";
  jQueryScript.crossOrigin = "anonymous";
  jQueryScript.referrerPolicy = "no-referrer";
  document.body.appendChild(jQueryScript);

  // Charger le plugin après que jQuery soit chargé
  jQueryScript.onload = () => {
    const pluginScript = document.createElement("script");
    pluginScript.src =
      "https://cdnjs.cloudflare.com/ajax/libs/malihu-custom-scrollbar-plugin/3.1.5/jquery.mCustomScrollbar.concat.min.js";
    pluginScript.integrity =
      "sha512-2hIlk2fL+NNHkULe9gGdma/T5vSYk80U5tvAFSy3dGEl8XD4h2i6frQvHv5B+bm/Itmi8nJ6krAcj5FWFcBGig==";
    pluginScript.crossOrigin = "anonymous";
    pluginScript.referrerPolicy = "no-referrer";
    document.body.appendChild(pluginScript);

    // Initialiser le plugin après le chargement du script
    pluginScript.onload = () => {
      if (typeof window.$ !== "undefined") {
        $("#portfolio").mCustomScrollbar({
          theme: "minimal-dark", // Thème préconstruit
          scrollInertia: 300, // Douceur du défilement
          advanced: {
            autoExpandHorizontalScroll: true, // Défilement horizontal si nécessaire
          },
        });
      }
    };
  };
  
  return (
    <>
    <GlobalStyle />
    <ErrorBoundary>
      <div className="container">
        {/* Barre latérale pour le contenu principal */}
        <aside className="sidebar-main">
          {/* Contenu de la barre latérale pour le contenu principal */}
        </aside>

        <section className="main-content">
          {/* Contenu principal de la page qui sera centré */}
          <CustomNavbar />

          {/* Définition des routes pour afficher les différents composants */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/agence" element={<Agence />} />
            <Route path="/actualites" element={<Actualites />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/article" element={<Article />} />
            <Route path="/projets" element={<Filtre />} />
            <Route path="/projet/:projectId" element={<MainArticle />}/>
            <Route path="/archives" element={<Archive />}
            />          
          </Routes>
          <ScrollTopButton />
          <Footer />
        </section>

        <section className="aside-content">
          {/* Barre latérale pour le contenu secondaire */}
          <aside className="sidebar-aside">
            {/* Contenu de la barre latérale pour le contenu secondaire */}
          </aside>

          {/* Affichage du composant Projet */}
          <Filtre />
        </section>
      </div>
      </ErrorBoundary>
    </>
  );
};



export default App;