import React from 'react';
import './WordCloudManual.css';

const WordCloudManual = ({ onWordClick }) => {
  return (
    <div className="word-cloud-container">

      {/* Colonne de gauche */}
      <div className="word word-theatre" onClick={() => onWordClick('THEATRE')}>
      THÉÂTRES
      </div>
      <div className="word word-regions" onClick={() => onWordClick('regions')}>
      RÉGIONS
      </div>
      <div className="word word-prive" onClick={() => onWordClick('PRIVÉ')}>
        PRIVÉ
      </div>
      <div className="word word-patrimoine" onClick={() => onWordClick('PATRIMOINE')}>
        PATRIMOINE
      </div>
      <div className="word word-bureaux" onClick={() => onWordClick('bureaux')}>
      BUREAUX
      </div>
      <div className="word word-public" onClick={() => onWordClick('PUBLIC')}>
        PUBLIC
      </div>

      {/* Colonne centrale */}
      <div className="word word-renovation" onClick={() => onWordClick('RENOVATION')}>
        RENOVATION
      </div>
      <div className="word word-ateliers" onClick={() => onWordClick('ateliers')}>
        ATELIERS
      </div>
      <div className="word word-neuf" onClick={() => onWordClick('NEUF')}>
      NEUF
      </div>
      <div className="word word-tous" onClick={() => onWordClick('TOUS')}>
        TOUS
      </div>
      <div className="word word-restauration" onClick={() => onWordClick('RESTAURATION')}>
        RESTAURATION
      </div>
      <div className="word word-amenagement" onClick={() => onWordClick('AMENAGEMENT')}>
      AMÉNAGEMENTS
      </div>

      {/* Colonne de droite */}
      <div className="word word-chateau" onClick={() => onWordClick('CHÂTEAU')}>
      CHÂTEAUX
      </div>
      <div className="word word-cinemas" onClick={() => onWordClick('CINÉMAS')}>
      CINÉMAS
      </div>
      <div className="word word-programmation" onClick={() => onWordClick('PROGRAMMATION')}>
        PROGRAMMATION
      </div>
      <div className="word word-extension" onClick={() => onWordClick('EXTENSION')}>
        EXTENSION
      </div>
      <div className="word word-musee" onClick={() => onWordClick('MUSÉE')}>
      MUSÉES
      </div>

      {/* Salle de spectacles */}
      <div
        className="word word-salle-spectacles"
        onClick={() => onWordClick('SALLE DE SPECTACLES')}
      >
        SALLES DE SPECTACLES
      </div>
    </div>
  );
};

export default WordCloudManual;
