import React, { useState, useEffect } from 'react';
import './projet.css';
import projetsData from './projets.json';
import images from '../../assets/img/projets/images';
import Masonry from 'react-masonry-css';
import { Link } from 'react-router-dom';
import WordCloudManual from './WordCloudManual'; // Import du composant

const Filtre = () => {
  const [projets, setProjets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeFilter, setActiveFilter] = useState('*');

  useEffect(() => {
    setProjets(projetsData);
    setCategories([
      ...new Set(
        projetsData.flatMap((projet) => projet.category)
      ),
    ]);
  }, []);

  const handleFilterClick = (value) => {
    setActiveFilter(value === 'TOUS' ? '*' : value);
  };

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  return (
    <section id="portfolio" className="portfolio">
      <div className="container-fluid">
        <div className="section-title"></div>
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            {/* Affichage du nuage de mots "figé" qui simule la photo 2 */}
            <WordCloudManual onWordClick={handleFilterClick} />
          </div>
        </div>

        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column aside-portfolio"
        >
          {projets
            .filter((projet) => {
              if (Array.isArray(projet.category)) {
                return (
                  activeFilter === '*' ||
                  projet.category.some((cat) =>
                    activeFilter.includes(cat)
                  )
                );
              } else {
                return activeFilter === '*' || projet.category === activeFilter;
              }
            })
            .map((projet) => (
              <div
                key={projet.id}
                className={`portfolio-item filter-${
                  Array.isArray(projet.category)
                    ? projet.category.join(' ').toLowerCase()
                    : projet.category.toLowerCase()
                }`}
              >
                <div className="portfolio-wrap">
                  <img
                    src={images[projet.image.publicUrl]}
                    className="img-fluid"
                    alt=""
                  />
                  <Link to={projet.linkDemo} data-gallery="portfolioGallery">
                    <div className="portfolio-info">
                      <h4>{projet.name.toUpperCase()}</h4>
                      <h4>{projet.location.toUpperCase()}</h4>
                      <p className="portfolio-category">
                        {Array.isArray(projet.category)
                          ? projet.category.join(', ')
                          : projet.category}
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
        </Masonry>
      </div>
    </section>
  );
};

export default Filtre;
