import React from 'react'
import projetData from '../../containers/projet/projets.json';
import { useParams } from 'react-router-dom';
import MainDescription from './MainDescription';
import MainSwiper from './MainSwiper';
import './MainArticle.css';

const MainArticle = () => {
  let { projectId } = useParams();

  const projet = projetData.find((p) => String(p.url) === String(projectId)); 
  const hasPartners = projet.partners && projet.partners.length > 0;
  const hasTeams = projet.teams && projet.teams.length > 0;

  // Logique pour scinder la description en deux colonnes
  // On calcule la longueur totale de tous les items "description"
// Joindre tous les segments en une seule chaîne
const joinedDescription = projet.description.join(' '); 
const totalLength = joinedDescription.length;
const halfLength = Math.floor(totalLength / 2);

// On split au mot (ou au caractère) pour faire un découpage plus fin
const words = joinedDescription.split(' ');

let firstColumn = '';
let secondColumn = '';
let currentLength = 0;
let switched = false;

words.forEach(word => {
  if (!switched) {
    if (currentLength + word.length > halfLength) {
      switched = true;
      secondColumn += word + ' ';
    } else {
      firstColumn += word + ' ';
      currentLength += word.length;
    }
  } else {
    secondColumn += word + ' ';
  }
});


  // Détermine le template des colonnes en fonction de la présence ou non des partenaires
  const gridColumns = hasPartners ? '1fr 1fr 1fr' : '1fr 1fr';

  return (
    <div className='main-article'>
      <MainSwiper pictures={projet.SwiperLandscape} />
      <br/>
      <h2 style={{marginTop: '1px'}}>
        {projet.name.toUpperCase()}, {projet.location.toUpperCase()}
      </h2>
      

      {/* Description en deux colonnes avant les trois colonnes de MainDescription, Teams, Partners */}
      <div className="description-text" style={{ marginBottom: '20px', display: 'flex', gap: '20px' }}>
        <div style={{ flex: 1 }}>
          <p style={{ margin: 'revert', color: 'black' }}>{firstColumn.trim()}</p>
        </div>
        <div style={{ flex: 1 }}>
          <p style={{ margin: 'revert', color: 'black', textAlign: 'end' }}>{secondColumn.trim()}</p>
        </div>
      </div>

      <div className="three-columns-container" 
           style={{
             display: 'grid', 
             gridTemplateColumns: gridColumns, 
             gap: '20px'
           }}
      >
        {/* Colonne de gauche : MainDescription */}
        <div className="description-column" style={{ textAlign: 'start', color: 'black' }}>
          <MainDescription projet={projet} />
        </div>

        {/* Colonne centrale : Équipe (teams) */}
        <div className="teams-column" style={{ textAlign: hasPartners ? 'center' : 'end', color: 'black' }}>
          <b>Equipe Maitre d'ouvrage</b><br/>
          {hasTeams ? projet.teams.map(team => <div key={team}>{team}</div>) : 'NC'}
        </div>

        {/* Colonne de droite : Partenaires (partners) seulement si hasPartners */}
        {hasPartners && (
          <div className="partners-column" style={{ textAlign: 'end', color: 'black' }}>
            <b>Principales entreprises</b><br />
            {projet.partners.map(partner => <div key={partner}>{partner}</div>)}
          </div>
        )}
      </div>
    </div>
  )
}

export default MainArticle
