import React from 'react';
import { Carousel } from 'react-bootstrap';
import projetsData from '../../containers/agence/agences.json';
import images from '../../assets/img/projets/images';
import 'bootstrap/dist/css/bootstrap.min.css';

const AgenceImages = () => {
    return (
        <div className="agence-slider">
            
            <Carousel>
      {projetsData.pics.map((pic, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={images[pic.url]} // Adapté pour correspondre à votre structure des images
            alt={pic.altText}
          />
        </Carousel.Item>
      ))}
    </Carousel>

        </div>
    );
}

export default AgenceImages;
